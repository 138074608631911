import Card from "@atoms/card";
import { Page } from "@atoms/layout/page";
import { PageBlock, PageResponsiveColumns } from "@atoms/layout/page-block";
import Tabs from "@atoms/tabs";
import { Section, SectionSmall, Title } from "@atoms/text";
import Assignments from "@components/assignations";
import { PageLoader } from "@components/page-loader";
import { useHasAccess } from "@features/auth/state/use-access";
import { useCustomer } from "@features/customers/state/use-customer";
import { extractCustomerFullName } from "@features/customers/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CustomerSummary from "./customer-summary";
import { DocumentManager } from "./document-manager";
import { Notes } from "./document-manager/notes";
import { Identity } from "./identity";
import { SharedProductsMenu } from "./menu";
import { CustomerNavigation } from "./navigation";
import RelationsGraph from "./relations-graph";
import {
  AlertsReview,
  KYTReview,
  OnboardingReview,
  PressReview,
  RiskReview,
} from "./review";
import { CustomerTimeline } from "./timeline";
import { CustomFields } from "./custom-fields";

export const CustomersDetailsPage = () => {
  const { id } = useParams();
  const { customer, loading, refresh } = useCustomer(id || "");
  const hasAccess = useHasAccess();
  const [tabValue, setTabValue] = useState("kyc");

  useEffect(() => {
    refresh();
  }, [id, refresh]);

  if (!customer?.details?.customer) {
    return (
      <Page>
        <Title className="flex items-center">
          <div className="grow">Details for {id}</div>
        </Title>
        <PageLoader />
      </Page>
    );
  }

  return (
    <Page>
      <Title className="flex items-center">
        <div className="grow">
          Details for{" "}
          {customer ? extractCustomerFullName(customer.details.customer) : ""}
        </div>
        <CustomerNavigation id={id!} />
      </Title>
      <SharedProductsMenu id={id!} />

      {!customer && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}

      {customer && (
        <>
          <CustomerSummary customer={customer}></CustomerSummary>

          {customer.details.customer.disabled && (
            <Card
              className="bg-slate-400 mt-4"
              text={
                <div className="text-white text-center flex flex-row items-center justify-center">
                  <InformationCircleIcon className="h-5 w-5 inline mr-2" />
                  This customer is disabled, it cannot be modified and is only
                  visible for audit purposes.
                </div>
              }
            />
          )}

          <div className="justify-between mt-2">
            <PageResponsiveColumns>
              <div className="shrink-0 lg:w-1/4 lg:max-w-sm w-full">
                <Assignments
                  type="customer"
                  id={customer.details.customer.id}
                  groups={customer.details.customer.review_groups || []}
                  readonly={!hasAccess("CUSTOMER_UPDATE")}
                />
                <CustomerTimeline
                  disabled={customer.details.customer.disabled}
                  revisions={customer.edd_history.edd_revisions}
                />
              </div>
              <div className="grow">
                <Tabs
                  value={tabValue}
                  onChange={(e) => setTabValue(e as string)}
                  tabs={[
                    {
                      value: "kyc",
                      label: "KYC",
                    },
                    {
                      value: "gokyt",
                      label: "KYT",
                    },
                    {
                      value: "gorisk",
                      label: "Risk Score",
                    },
                    {
                      value: "data",
                      label: "Additional data",
                    },
                  ]}
                ></Tabs>
                {tabValue === "kyc" && (
                  <>
                    <Section className="mt-4">Identity</Section>
                    <Identity customer={customer} edit />
                    {hasAccess("SIDENAV_SESSION") && (
                      <>
                        <Section className="mt-4">Go!Vid</Section>
                        <OnboardingReview customer={customer} />
                      </>
                    )}
                    {hasAccess("SIDENAV_ALERT") && (
                      <>
                        <Section className="mt-4">Go!Scan</Section>
                        <AlertsReview customer={customer} />
                      </>
                    )}
                    {hasAccess("PRESS") && (
                      <>
                        <Section className="mt-4">Go!Press</Section>
                        <PressReview customer={customer} />{" "}
                      </>
                    )}
                    {hasAccess("IMPORT_SETUP_READ") && (
                      <>
                        <Section className="mt-4">Documents and notes</Section>
                        <PageBlock className="mb-2">
                          <Notes customer={customer} />
                          <hr className="my-4 -mx-4" />
                          <SectionSmall className="mt-4">
                            Documents
                          </SectionSmall>
                          <div className="mt-2" />
                          <DocumentManager customer={customer} />
                        </PageBlock>
                      </>
                    )}
                    {hasAccess("RISK_SCORING_READ") && (
                      <>
                        <Section className="mt-4">Relations</Section>
                        <PageBlock className="mb-2">
                          <div className="-m-4">
                            <RelationsGraph customer={customer} />
                          </div>
                          <div className="mt-2" />
                        </PageBlock>
                      </>
                    )}
                  </>
                )}

                {tabValue === "gokyt" && (
                  <>
                    {hasAccess("KYT") && (
                      <div className="mt-4">
                        <KYTReview customer={customer} />
                      </div>
                    )}
                  </>
                )}
                {tabValue === "gorisk" && (
                  <>
                    {hasAccess("DASHBOARD_RISK_SCORING") && (
                      <div className="mt-4">
                        <RiskReview customer={customer} />
                      </div>
                    )}
                  </>
                )}
                {tabValue === "data" && (
                  <>
                    <PageBlock className="mt-4 !py-3">
                      <CustomFields
                        customer={customer}
                        displayByRelatedProduct="none"
                      />
                    </PageBlock>

                    {hasAccess("IMPORT_SETUP_READ") && (
                      <>
                        <Section className="mt-4">Documents and notes</Section>
                        <PageBlock className="mb-2">
                          <Notes customer={customer} />
                          <hr className="my-4 -mx-4" />
                          <SectionSmall className="mt-4">
                            Documents
                          </SectionSmall>
                          <div className="mt-2" />
                          <DocumentManager customer={customer} />
                        </PageBlock>
                      </>
                    )}
                  </>
                )}
              </div>
            </PageResponsiveColumns>
          </div>
        </>
      )}
    </Page>
  );
};
